/**
 * Inventis normalize.css
 * Based on normalize.css, sanitize.css and our own tweaks
 */

/* autoprefixer: off */
/* stylelint-disable property-no-vendor-prefix, selector-no-vendor-prefix, value-no-vendor-prefix */

/* Document
 * ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
    background-repeat: no-repeat; /* 1 */
    box-sizing: border-box; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
    text-decoration: inherit; /* 1 */
    vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in in iOS.
 * 6. Remove 350ms delay on ios (dependend on <meta name="viewport" content="width=device-width">).
 */

html {
    cursor: default; /* 1 */
    font-family:
            system-ui,
                /* macOS 10.11-10.12 */ -apple-system,
                /* Windows 6+ */ 'Segoe UI',
                /* Android 4+ */ 'Roboto',
                /* Ubuntu 10.10+ */ 'Ubuntu',
                /* Gnome 3+ */ 'Cantarell',
                /* KDE Plasma 5+ */ 'Noto Sans',
                /* fallback */ sans-serif,
                /* macOS emoji */ 'Apple Color Emoji',
                /* Windows emoji */ 'Segoe UI Emoji',
                /* Windows emoji */ 'Segoe UI Symbol',
                /* Linux emoji */ 'Noto Color Emoji'; /* 2 */

    line-height: 1.15; /* 3 */
    -moz-tab-size: 4; /* 4 */
    tab-size: 4; /* 4 */
    -ms-text-size-adjust: 100%; /* 5 */
    -webkit-text-size-adjust: 100%; /* 5 */
    touch-action: manipulation; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * 1. Remove the margin in all browsers (opinionated).
 * 2. Don't let long words break the layout on small area's
 * 3. Fix positioning issues in some rare cases
 */

body {
    margin: 0; /* 1 */
    position: relative; /* 3 */
    overflow-wrap: break-word; /* 2 */
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
}

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
    font-family:
            /* macOS 10.10+ */ 'Menlo',
        /* Windows 6+ */ 'Consolas',
        /* Android 4+ */ 'Roboto Mono',
        /* Ubuntu 10.10+ */ 'Ubuntu Monospace',
        /* KDE Plasma 5+ */ 'Noto Mono',
        /* KDE Plasma 4+ */ 'Oxygen Mono',
        /* Linux/OpenOffice fallback */ 'Liberation Mono',
        /* fallback */ monospace; /* 1 */

    font-size: 1em; /* 2 */
}

/**
 * We prefer normal font style for address (optionated)
 */

address {
    font-style: normal;
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge, Opera, and Safari.
 */
/* stylelint-disable declaration-block-no-duplicate-properties */
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
    font-weight: bolder;
}

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
    font-family:
            /* macOS 10.10+ */ 'Menlo',
        /* Windows 6+ */ 'Consolas',
        /* Android 4+ */ 'Roboto Mono',
        /* Ubuntu 10.10+ */ 'Ubuntu Monospace',
        /* KDE Plasma 5+ */ 'Noto Mono',
        /* KDE Plasma 4+ */ 'Oxygen Mono',
        /* Linux/OpenOffice fallback */ 'Liberation Mono',
        /* fallback */ monospace; /* 1 */

    font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
    font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browers (opinionated).
 */

audio,
canvas,
iframe,
svg,
video {
    vertical-align: middle;
}

/*
 * Make sure some media elements don't break the layout.
 */

iframe {
    max-width: 100%;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
    fill: currentColor;
}

/**
 * 1. Don't behave as inline element by default which creates weird situations with margins
 * 2. Never stretch images or let them go out of the viewport
 * 3. Keep original image ratio
 */

img {
    display: inline-block; /* 1 */
    height: auto; /* 3 */
    max-width: 100%; /* 2 */
    vertical-align: top;
}

/**
 * Inherit parent color, more convenient than default browser blue
 */
a {
    color: inherit;
}

/* Forms
 * ========================================================================== */

/**
 * Inherit styling in all browsers (opinionated).
 */

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

/**
 * Remove the margin in Safari.
 */

button,
input,
select {
    margin: 0;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox.
 */

button {
    text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

/**
 * Show the overflow in Edge.
 */

input {
    overflow: visible;
}

/**
 * HARD reset to remove shadow in input for IOS
 */
input:not([type='checkbox'], [type='radio']) {
    -webkit-appearance: none !important;
}

/**
 * Correct the text wrapping in Edge.
 */

legend {
    display: table;
    max-width: 100%;
    white-space: normal;
}

/**
 * 1. Add the correct display in Edge.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
    display: inline-block; /* 1 */
    vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
    text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 * 3. Makes sure you can change the height with the resize handle
 */

textarea {
    height: auto; /* 3 */
    margin: 0; /* 1 */
    resize: vertical; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -.2rem; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
    color: inherit;
    opacity: .54;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge.
 */

details {
    display: block;
}

/*
 * Add the correct styles in Edge, and Safari.
 */

dialog {
    background-color: white;
    border: solid;
    color: black;
    display: block;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    left: 0;
    margin: auto;
    padding: 1em;
    position: absolute;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}

dialog:not([open]) {
    display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
    display: list-item;
}

/* User interaction
 * ========================================================================== */

/*
 * Remove the tapping delay on clickable elements in all browsers (opinionated).
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
    touch-action: manipulation;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy='true'] {
    cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
    cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled='true'],
[disabled] {
    cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden='false'][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    position: absolute;
}

/* stylelint-enable */
